@font-face {
    font-family: 'SFR-Bold';
    src: url('/assets/fonts/SFRounded-Bold.woff2') format('woff2'),
        url('/assets/fonts/SFRounded-Bold.woff') format('woff');
    font-weight: bold;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'SFR-Medium';
    src: url('/assets/fonts/SFRounded-Medium.woff2') format('woff2'),
        url('/assets/fonts/SFRounded-Medium.woff') format('woff');
    font-weight: 500;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'SFR-Semibold';
    src: url('/assets/fonts/SFRounded-Semibold.woff2') format('woff2'),
        url('/assets/fonts/SFRounded-Semibold.woff') format('woff');
    font-weight: 600;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'SFR-Regular';
    src: url('/assets/fonts/SFRounded-Regular.woff2') format('woff2'),
        url('/assets/fonts/SFRounded-Regular.woff') format('woff');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

