@font-face {
  font-family: SFR-Bold;
  src: url("SFRounded-Bold.adb5a818.woff2") format("woff2"), url("SFRounded-Bold.1e8e832c.woff") format("woff");
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: SFR-Medium;
  src: url("SFRounded-Medium.baa8cdbf.woff2") format("woff2"), url("SFRounded-Medium.dcb5786e.woff") format("woff");
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: SFR-Semibold;
  src: url("SFRounded-Semibold.633ac732.woff2") format("woff2"), url("SFRounded-Semibold.30d367ad.woff") format("woff");
  font-weight: 600;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: SFR-Regular;
  src: url("SFRounded-Regular.56666084.woff2") format("woff2"), url("SFRounded-Regular.91fcf2d8.woff") format("woff");
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

:root {
  --color-primary: #00b7d1;
  --color-secondary: #5061bd;
  --color-soft-black: #343434;
  --main-font-color: #57575a;
}

.position-r {
  position: relative;
}

.uppercase {
  text-transform: uppercase;
}

.main-title {
  font-family: SFR-Regular, sans-serif;
  font-size: 3rem;
}

@media screen and (width <= 540px) {
  .main-title {
    font-size: 2rem;
    line-height: 44px;
  }
}

.subtitle {
  font-family: SFR-Medium, sans-serif;
  font-size: 2rem;
}

@media screen and (width <= 540px) {
  .subtitle {
    font-size: 1.5rem;
    line-height: 36px;
  }
}

body, h1, h2, h3, h4, p, a, td, th, table {
  color: var(--main-font-color);
  margin: 0;
  padding: 0;
  font-family: SFR-Regular, sans-serif;
}

.main-container {
  box-sizing: border-box;
  width: 100%;
  margin-left: auto;
  margin-right: auto;
  padding-left: 3.5rem;
  padding-right: 3.5rem;
}

@media screen and (width >= 1201px) {
  .main-container {
    max-width: 1536px;
  }
}

@media screen and (width >= 901px) and (width <= 1200px) {
  .main-container {
    max-width: 1140px;
  }
}

@media screen and (width <= 900px) {
  .main-container {
    padding-left: 1.5rem;
    padding-right: 1.5rem;
  }
}

.main-container .main-header__row {
  flex-flow: wrap;
  place-content: center space-between;
  align-items: center;
  min-height: 5rem;
  padding-top: .2rem;
  padding-bottom: .2rem;
  display: flex;
}

.main-container .main-header__row .col-navigation__item {
  color: #fff;
  margin-right: 2rem;
  font-family: SFR-Medium, sans-serif;
  font-size: 1.1rem;
  text-decoration: none;
}

@media screen and (width <= 900px) {
  .main-container .main-header__row .col-navigation__item {
    color: var(--main-font-color);
  }
}

.main-container .main-header__row .col-navigation__item:last-child {
  margin-right: 0;
}

#main-header {
  z-index: 100;
  width: 100%;
  transition: background-color .3s;
  position: fixed;
  top: 0;
  left: 0;
}

#main-header .main-logo {
  width: 10.87rem;
}

@media screen and (width <= 900px) {
  #main-header {
    display: none;
  }
}

#main-header.transparent {
  background-color: #0000;
}

#main-header.scrolled {
  background-color: #fff;
  border: 1px solid #dedede;
}

#main-header.scrolled .col-navigation__item {
  color: var(--color-soft-black);
}

.alaires__hero {
  background: url("hero-section-bg.6fcb4ad2.png") center / cover no-repeat;
  flex-flow: wrap;
  min-height: 62rem;
  margin-top: 0;
  display: flex;
}

@media screen and (width <= 900px) {
  .alaires__hero {
    background: url("hero-mobile-bg.d7d239b9.png") center / cover no-repeat;
    min-height: 48rem;
  }
}

@media screen and (width <= 540px) {
  .alaires__hero {
    min-height: 40rem;
  }
}

.alaires__hero .main-row {
  height: 50%;
  margin-top: 16.5rem;
}

@media screen and (width <= 540px) {
  .alaires__hero .main-row {
    margin-top: 10.5rem;
  }
}

.alaires__hero .main-row .col-hero__title {
  width: 36.5rem;
}

@media screen and (width <= 900px) {
  .alaires__hero .main-row .col-hero__title {
    text-align: center;
    width: 90%;
    margin: 0 auto;
  }
}

.alaires__hero .main-row .col-hero__title .contact-btn {
  background: var(--color-secondary);
  color: #fff;
  border-radius: 8rem;
  justify-content: center;
  align-items: center;
  margin-top: 3rem;
  padding: 1rem 2rem;
  font-family: SFR-Bold, sans-serif;
  font-size: 1rem;
  text-decoration: none;
  transition: background-color .2s;
  display: inline-flex;
}

.alaires__hero .main-row .col-hero__title .contact-btn .whatsapp-icon {
  margin-right: .5rem;
}

.alaires__hero .main-row .col-hero__title .contact-btn:hover {
  background-color: #748aff;
}

.feature-services {
  text-align: center;
  padding-bottom: 8rem;
}

.feature-services .main-row {
  margin-top: 1rem;
}

.feature-services .main-row__services {
  justify-content: space-around;
  align-items: flex-start;
  margin-top: 5.2rem;
  display: flex;
}

@media screen and (width <= 900px) {
  .feature-services .main-row__services {
    flex-direction: column;
  }
}

.feature-services .main-row__services .col {
  flex: 0 0 33.3333%;
  width: 33.3333%;
}

@media screen and (width <= 900px) {
  .feature-services .main-row__services .col {
    width: 100%;
  }
}

.feature-services .main-row__services .col__container {
  padding: 1.5rem 1.5rem 2rem;
}

.feature-services .main-row__services .col__container .service-icon {
  width: 4rem;
}

.feature-services .main-row__services .col__container .service-title {
  margin-top: 1rem;
  margin-bottom: .5rem;
  font-family: SFR-Bold, sans-serif;
  font-size: 1.125rem;
  line-height: 26px;
}

.feature-services .main-row__services .col__container .service-description {
  line-height: 24px;
}

.feature-services .main-row__btn {
  margin-top: 3rem;
}

.feature-services .main-row__btn .btn-schedule {
  background-color: var(--color-primary);
  color: #fff;
  border-radius: 2rem;
  padding: 1rem 2rem;
  font-family: SFR-Bold, sans-serif;
  text-decoration: none;
  transition: background-color .2s;
}

.feature-services .main-row__btn .btn-schedule:hover {
  background-color: #29e0fa;
}

.solutions {
  text-align: center;
  background: url("bg-solutions-v2.60739c29.png") center / 100% 100% no-repeat;
  align-content: center;
  align-items: center;
  min-height: 60rem;
  padding-bottom: 8rem;
  display: flex;
}

@media screen and (width <= 900px) {
  .solutions {
    background: url("solutions-mobile-bg.3a5bf537.png") center / cover no-repeat;
    padding: 3rem 0;
  }
}

@media screen and (width <= 540px) {
  .solutions {
    min-height: inherit;
    max-height: inherit;
  }
}

.solutions .subtitle {
  color: #fff;
  margin-top: 10rem;
}

@media screen and (width >= 1052px) {
  .solutions .subtitle {
    margin-top: 8rem;
  }
}

@media screen and (width <= 900px) {
  .solutions .subtitle {
    margin-top: 3rem;
  }
}

@media screen and (width <= 540px) {
  .solutions .subtitle {
    margin-top: 2rem;
  }
}

.solutions .main-row {
  box-sizing: border-box;
  flex-wrap: wrap;
  justify-content: center;
  margin-top: 5rem;
  display: flex;
}

@media screen and (width >= 1201px) {
  .solutions .main-row {
    max-width: 100%;
    margin: 5rem auto 0;
  }
}

@media screen and (width <= 540px) {
  .solutions .main-row {
    flex-direction: column;
  }
}

.solutions .main-row .col {
  flex: 0 0 25%;
  width: 25%;
  margin-bottom: 2.5rem;
}

@media screen and (width <= 1052px) {
  .solutions .main-row .col {
    flex: 0 0 33.3333%;
    width: 33.3333%;
  }
}

@media screen and (width <= 768px) and (width >= 540px) {
  .solutions .main-row .col {
    flex: 0 0 50%;
    width: 50%;
  }
}

@media screen and (width <= 540px) {
  .solutions .main-row .col {
    width: 100%;
  }
}

.solutions .main-row .col .col__container {
  text-align: center;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 1.5rem 1.5rem 2rem;
  display: flex;
}

.solutions .main-row .col .col__container .service-image--container {
  background-color: #fff;
  border-radius: 50%;
  max-width: 8.5rem;
  margin-bottom: 1rem;
  padding: 1.5625rem;
}

.solutions .main-row .col .col__container .service-image--container img {
  max-width: 5rem;
  margin-bottom: 0 !important;
}

.solutions .main-row .col .col__container .service-image {
  width: 13.375rem;
  margin-bottom: 1rem;
}

.solutions .main-row .col .col__container p {
  color: #fff;
  margin: 0 auto;
  line-height: 24px;
}

.experience {
  text-align: center;
  padding-top: 4rem;
  padding-bottom: 4rem;
}

.experience .subtitle {
  margin-bottom: 4.25rem;
}

.experience .main-row {
  flex-wrap: wrap;
  place-content: center;
  align-items: center;
  display: flex;
}

@media screen and (width <= 540px) {
  .experience .main-row {
    flex-direction: column;
  }
}

.experience .main-row .col {
  flex: 0 0 25%;
  width: 33.3333%;
}

@media screen and (width <= 540px) {
  .experience .main-row .col {
    width: 100%;
    margin-bottom: 4rem;
  }
}

.experience .main-row .col .figure {
  color: var(--color-primary);
  font-family: SFR-Bold, sans-serif;
  font-size: 4rem;
}

.experience .main-row .col .description {
  margin-top: .5rem;
  font-size: 1.125rem;
}

.ethic-work {
  background: url("ethic-work-bg.d86f6b3a.png") center / 100% 100% no-repeat;
  place-content: center flex-end;
  align-items: center;
  min-height: 30.5rem;
  max-height: 100vh;
  margin-top: 6.5rem;
  display: flex;
}

@media screen and (width <= 900px) {
  .ethic-work {
    background: url("ethic-work-mobile-bg.7fd726c3.png") center / cover no-repeat;
  }
}

.ethic-work .main-container {
  justify-content: flex-end;
  display: flex;
}

.ethic-work .main-container .main-row {
  width: 100%;
  max-width: 50%;
}

@media screen and (width <= 900px) {
  .ethic-work .main-container .main-row {
    text-align: center;
    max-width: 100%;
  }
}

.ethic-work .main-container .main-row .col-ethic-work .col__container {
  padding: 1.5rem 1.5rem 2.5rem;
}

.ethic-work .main-container .main-row .col-ethic-work .col__container .ethic-work__title {
  color: #fff;
  margin-bottom: 1.5rem;
  font-family: SFR-Medium, sans-serif;
}

.ethic-work .main-container .main-row .col-ethic-work .col__container .ethic-work__parag {
  color: #fff;
  font-size: 1.25rem;
}

.ethic-work .main-container .main-row .col-ethic-work .col__container .btn__ethic-work {
  background-color: var(--color-secondary);
  color: #fff;
  border-radius: 2rem;
  margin-top: 1.5rem;
  padding: 1rem 2rem;
  font-family: SFR-Bold, sans-serif;
  text-decoration: none;
  transition: background-color .2s;
  display: inline-block;
}

.ethic-work .main-container .main-row .col-ethic-work .col__container .btn__ethic-work:hover {
  background-color: #748aff;
}

.footer {
  background: url("footer-bg.9d454965.png") center / 100% 100% no-repeat;
  align-content: center;
  align-items: center;
  min-height: 18.5rem;
  max-height: 100vh;
  margin-top: 6.5rem;
  display: flex;
}

@media screen and (width <= 900px) {
  .footer {
    min-height: inherit;
    text-align: center;
    background-color: #3a4445;
    border-radius: 7.18rem 0 0;
    padding-top: 4rem;
    padding-bottom: 2rem;
  }
}

.footer .main-container .main-row {
  justify-content: space-around;
  align-items: center;
  display: flex;
}

@media screen and (width <= 900px) {
  .footer .main-container .main-row {
    flex-direction: column;
  }

  .footer .main-container .main-row .col {
    margin-bottom: 2rem;
  }
}

.footer .main-container .main-row .col p {
  color: #fff;
  font-family: SFR-Bold, sans-serif;
  font-size: 1.1rem;
}

.footer .main-container .main-row .col__logo p {
  margin-top: 1rem;
}

.footer .main-container .main-row .col-tel, .footer .main-container .main-row .col-mail {
  align-content: center;
  align-items: center;
  display: flex;
}

.footer .main-container .main-row .col-tel img, .footer .main-container .main-row .col-mail img {
  width: 1.1rem;
  margin-right: .5rem;
}

@media screen and (width <= 900px) {
  .footer .main-container .main-row .col-social-media {
    margin-top: 2rem;
  }
}

.footer .main-container .main-row .col-social-media a {
  margin-right: 2rem;
}

.footer .main-container .main-row .col-social-media a:last-child {
  margin-right: 0;
}

.mobile-header {
  z-index: 999;
  background: #fff;
  border-bottom: 1px solid #d2d5da;
  width: 100%;
  padding-top: 1rem;
  padding-bottom: 1rem;
  position: fixed;
}

.mobile-header .main-logo {
  width: 8rem;
}

.mobile-header__container {
  place-content: center space-between;
  align-items: center;
  display: flex;
}

@media screen and (width >= 900px) {
  .mobile-header {
    display: none;
  }
}

.menu-mobile {
  z-index: 9999;
  background: #fff;
  width: 100%;
  height: 100vh;
  position: fixed;
}

@media screen and (width >= 900px) {
  .menu-mobile {
    display: none;
  }
}

.menu-mobile .menu-mobile__container {
  border-bottom: 1px solid #d2d5da;
}

.menu-mobile .menu-mobile__container .row__menu-mobile {
  place-content: center space-between;
  align-items: center;
  padding-top: 1rem;
  padding-bottom: 1rem;
  display: flex;
}

.menu-mobile .menu-mobile__container .row__menu-mobile .main-logo {
  width: 8rem;
}

.menu-mobile .menu-mobile__container-content .row-links {
  flex-direction: column;
  display: flex;
}

.menu-mobile .menu-mobile__container-content .row-links a {
  border-bottom: 1px solid #ececec;
  padding-top: 1.5rem;
  padding-bottom: 1.5rem;
  font-family: SFR-Medium, sans-serif;
  font-size: 1.5rem;
  text-decoration: none;
  display: block;
}

.menu-mobile .menu-mobile__container-content .btn-contact {
  text-align: center;
  margin-top: 4rem;
}

.menu-mobile .menu-mobile__container-content .btn-contact a {
  background-color: var(--color-secondary);
  color: #fff;
  border-radius: 2rem;
  width: 60%;
  margin: 1.5rem auto 0;
  padding: 1rem 2rem;
  font-family: SFR-Bold, sans-serif;
  text-decoration: none;
  display: block;
}

.hide {
  max-height: 0;
  transition: max-height .5s ease-out;
  overflow: hidden;
}

.visible {
  max-height: 100vh;
}
/*# sourceMappingURL=index.d836e98c.css.map */
