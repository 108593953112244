@import './fonts';

//CSS variables
:root {
  --color-primary: #00b7d1;
  --color-secondary: #5061bd;
  --color-soft-black: #343434;
  --main-font-color: #57575a;
}

// Fonts
$main-font: 'SFR-Regular', sans-serif;
$medium-font: 'SFR-Medium', sans-serif;
$semibold-font: 'SFR-Semibold', sans-serif;
$bold-font: 'SFR-Bold', sans-serif;

// Screens sizes
$mobile: 540px;
$tablet: 900px;
$minor-tablet: 768px;
$mid-desktop: 1052px;
$desktop: 1200px;

// Utilities
.position-r {
  position: relative;
}
.uppercase {
  text-transform: uppercase;
}

// Font-sizes
.main-title {
  font-family: $main-font;
  font-size: 3rem;
  @media screen and (max-width: $mobile) {
    font-size: 2rem;
    line-height: 44px;
  }
}
.subtitle {
  font-family: $medium-font;
  font-size: 2rem;
  @media screen and (max-width: $mobile) {
    font-size: 1.5rem;
    line-height: 36px;
  }
}

body,
h1,
h2,
h3,
h4,
p,
a,
td,
th,
table {
  margin: 0;
  padding: 0;
  color: var(--main-font-color);
  font-family: $main-font;
}

.main-container {
  box-sizing: border-box;
  padding-right: 3.5rem;
  padding-left: 3.5rem;
  width: 100%;
  margin-right: auto;
  margin-left: auto;

  @media screen and (min-width: $desktop + 1) {
    max-width: 1536px;
  }

  @media screen and (min-width: $tablet + 1) and (max-width: $desktop) {
    max-width: 1140px;
  }

  @media screen and (max-width: $tablet) {
    padding-right: 1.5rem;
    padding-left: 1.5rem;
  }

  .main-header__row {
    min-height: 5rem;
    display: flex;
    flex-flow: row wrap;
    align-items: center;
    align-content: center;
    justify-content: space-between;
    padding-top: 0.2rem;
    padding-bottom: 0.2rem;
    .col-navigation {
      &__item {
        text-decoration: none;
        color: #fff;
        font-size: 1.1rem;
        font-family: $medium-font;
        margin-right: 2rem;

        @media screen and (max-width: $tablet) {
          color: var(--main-font-color);
        }

        &:last-child {
          margin-right: 0;
        }
      }
    }
  }
}

#main-header {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 100;
  transition: background-color 0.3s ease;
  .main-logo {
    width: 10.87rem;
  }

  @media screen and (max-width: $tablet) {
    display: none;
  }
}

#main-header.transparent {
  background-color: transparent;
}

#main-header.scrolled {
  background-color: #fff;
  border: 1px solid #dedede;
  .col-navigation__item {
    color: var(--color-soft-black);
  }
}

.alaires__hero {
  background: url('/assets/img/hero-section-bg.png') center no-repeat;
  background-size: cover;
  min-height: 62rem;
  display: flex;
  flex-flow: row wrap;
  margin-top: 0;

  @media screen and (max-width: $tablet) {
    background: url('/assets/img/hero-mobile-bg.png') center no-repeat;
    background-size: cover;
    min-height: 48rem;
  }

  @media screen and (max-width: $mobile) {
    min-height: 40rem;
  }

  .main-row {
    height: 50%;
    margin-top: 16.5rem;

    @media screen and (max-width: $mobile) {
      margin-top: 10.5rem;
    }
    .col-hero {
      &__title {
        width: 36.5rem;

        @media screen and (max-width: $tablet) {
          width: 90%;
          margin: 0 auto;
          text-align: center;
        }

        .contact-btn {
          margin-top: 3rem;
          background: var(--color-secondary);
          text-decoration: none;
          padding: 1rem 2rem;
          color: #fff;
          font-family: $bold-font;
          font-size: 1rem;
          border-radius: 8rem;
          display: inline-flex;
          justify-content: center;
          align-items: center;
          transition: 0.2s background-color ease;
          .whatsapp-icon {
            margin-right: 0.5rem;
          }
          &:hover {
            background-color: #748aff;
          }
        }
      }
    }
  }
}

.feature-services {
  padding-bottom: 8rem;
  text-align: center;
  .main-row {
    margin-top: 1rem;

    &__services {
      display: flex;
      align-items: flex-start;
      justify-content: space-around;
      margin-top: 5.2rem;

      @media screen and (max-width: $tablet) {
        flex-direction: column;
      }

      .col {
        flex: 0 0 33.3333333333%;
        width: 33.3333333333%;

        @media screen and (max-width: $tablet) {
          width: 100%;
        }

        &__container {
          padding: 1.5rem 1.5rem 2rem;

          .service-icon {
            width: 4rem;
          }

          .service-title {
            margin-top: 1rem;
            margin-bottom: 0.5rem;
            font-family: $bold-font;
            font-size: 1.125rem;
            line-height: 26px;
          }

          .service-description {
            line-height: 24px;
          }
        }
      }
    }
  }
  .main-row__btn {
    margin-top: 3rem;

    .btn-schedule {
      background-color: var(--color-primary);
      text-decoration: none;
      color: #fff;
      font-family: $bold-font;
      padding: 1rem 2rem;
      border-radius: 2rem;
      transition: 0.2s background-color ease;
      &:hover {
        background-color: #29e0fa;
      }
    }
  }
}

.solutions {
  background: url('/assets/img/bg-solutions-v2.png') center no-repeat;
  padding-bottom: 8rem;
  background-size: cover;
  background-size: 100% 100%;
  text-align: center;
  min-height: 60rem;
  display: flex;
  align-items: center;
  align-content: center;

  @media screen and (max-width: $tablet) {
    background: url('/assets/img/solutions-mobile-bg.png') center no-repeat;
    background-size: cover;
    padding: 0;
    padding-top: 3rem;
    padding-bottom: 3rem;
  }

  @media screen and (max-width: $mobile) {
    min-height: inherit;
    max-height: inherit;
  }

  .subtitle {
    margin-top: 10rem;

    @media screen and (min-width: $mid-desktop) {
      margin-top: 8rem;
    }
    color: #fff;

    @media screen and (max-width: $tablet) {
      margin-top: 3rem;
    }

    @media screen and (max-width: $mobile) {
      margin-top: 2rem;
    }
  }
  .main-row {
    margin-top: 5rem;
    box-sizing: border-box;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;

    @media screen and (min-width: $desktop + 1) {
      max-width: 100%;
      margin: 0 auto;
      margin-top: 5rem;
    }

    @media screen and (max-width: $mobile) {
      flex-direction: column;
    }

    .col {
      flex: 0 0 25%;
      width: 25%;
      margin-bottom: 2.5rem;

      @media screen and (max-width: $mid-desktop) {
        flex: 0 0 33.3333%;
        width: 33.3333%;
      }

      @media screen and (max-width: $minor-tablet) and (min-width: $mobile) {
        flex: 0 0 50%;
        width: 50%;
      }

      @media screen and (max-width: $mobile) {
        width: 100%;
      }

      .col__container {
        padding: 1.5rem 1.5rem 2rem;
        text-align: center;
        display: flex;
        flex-direction: column;
        align-items: center; 
        justify-content: center;
        .service-image--container {
          background-color: #fff;
          padding: 1.5625rem;
          max-width: 8.5rem;
          border-radius: 50%;
          margin-bottom: 1rem;
        }
        .service-image--container img {
          max-width: 5rem;
          margin-bottom: 0 !important;
        }
        .service-image {
          width: 13.375rem;
          margin-bottom: 1rem;
        }
        p {
          margin: 0 auto;
          color: #fff;
          line-height: 24px;
        }
      }
    }
  }
}

.experience {
  text-align: center;
  padding-top: 4rem;
  padding-bottom: 4rem;
  .subtitle {
    margin-bottom: 4.25rem;
  }
  .main-row {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    align-content: center;
    justify-content: center;

    @media screen and (max-width: $mobile) {
      flex-direction: column;
    }

    .col {
      flex: 0 0 25%;
      width: 33.333333%;

      @media screen and (max-width: $mobile) {
        width: 100%;
        margin-bottom: 4rem;
      }
      .figure {
        font-family: $bold-font;
        font-size: 4rem;
        color: var(--color-primary);
      }
      .description {
        margin-top: 0.5rem;
        font-size: 1.125rem;
      }
    }
  }
}

.ethic-work {
  background: url('/assets/img/ethic-work-bg.png') center no-repeat;
  background-size: cover;
  background-size: 100% 100%;
  max-height: 100vh;
  min-height: 30.5rem;
  display: flex;
  align-items: center;
  align-content: center;
  justify-content: flex-end;
  margin-top: 6.5rem;

  @media screen and (max-width: $tablet) {
    background: url('/assets/img/ethic-work-mobile-bg.png') center no-repeat;
    background-size: cover;
  }

  .main-container {
    display: flex;
    justify-content: flex-end;
    .main-row {
      max-width: 50%;
      width: 100%;

      @media screen and (max-width: $tablet) {
        max-width: 100%;
        text-align: center;
      }

      .col-ethic-work {
        .col__container {
          padding: 1.5rem 1.5rem 2.5rem;
          .ethic-work__title {
            font-family: $medium-font;
            color: #fff;
            margin-bottom: 1.5rem;
          }
          .ethic-work__parag {
            color: #fff;
            font-size: 1.25rem;
          }
          .btn__ethic-work {
            background-color: var(--color-secondary);
            text-decoration: none;
            color: #fff;
            font-family: $bold-font;
            padding: 1rem 2rem;
            border-radius: 2rem;
            display: inline-block;
            margin-top: 1.5rem;
            transition: 0.2s background-color ease;
            &:hover {
              background-color: #748aff;
            }
          }
        }
      }
    }
  }
}

.footer {
  background: url('/assets/img/footer-bg.png') center no-repeat;
  background-size: cover;
  background-size: 100% 100%;
  max-height: 100vh;
  min-height: 18.5rem;
  display: flex;
  align-items: center;
  align-content: center;
  margin-top: 6.5rem;

  @media screen and (max-width: $tablet) {
    background-color: #3a4445;
    border-radius: 7.18rem 0 0 0;
    min-height: inherit;
    padding-top: 4rem;
    padding-bottom: 2rem;
    text-align: center;
  }
  .main-container {
    .main-row {
      display: flex;
      align-items: center;
      justify-content: space-around;

      @media screen and (max-width: $tablet) {
        flex-direction: column;
      }
      .col {
        @media screen and (max-width: $tablet) {
          margin-bottom: 2rem;
        }
        p {
          color: #fff;
          font-family: $bold-font;
          font-size: 1.1rem;
        }
      }
      .col__logo {
        p {
          margin-top: 1rem;
        }
      }
      .col-tel,
      .col-mail {
        display: flex;
        align-items: center;
        align-content: center;
        img {
          margin-right: 0.5rem;
          width: 1.1rem;
        }
      }
      .col-social-media {
        @media screen and (max-width: $tablet) {
          margin-top: 2rem;
        }
        a {
          margin-right: 2rem;
          &:last-child {
            margin-right: 0;
          }
        }
      }
    }
  }
}

// Header mobile
.mobile-header {
  background: #fff;
  padding-top: 1rem;
  padding-bottom: 1rem;
  border-bottom: 1px solid #d2d5da;
  position: fixed;
  width: 100%;
  z-index: 999;
  .main-logo {
    width: 8rem;
  }

  &__container {
    display: flex;
    align-items: center;
    align-content: center;
    justify-content: space-between;
  }

  @media screen and (min-width: $tablet) {
    display: none;
  }
}

// Menu mobile
.menu-mobile {
  background: #fff;
  width: 100%;
  height: 100vh;
  position: fixed;
  z-index: 9999;

  @media screen and (min-width: $tablet) {
    display: none;
  }

  .menu-mobile__container {
    border-bottom: 1px solid #d2d5da;
    .row__menu-mobile {
      display: flex;
      align-items: center;
      align-content: center;
      justify-content: space-between;
      padding-top: 1rem;
      padding-bottom: 1rem;
      .main-logo {
        width: 8rem;
      }
    }
  }

  .menu-mobile__container-content {
    .row-links {
      display: flex;
      flex-direction: column;
      a {
        text-decoration: none;
        font-family: $medium-font;
        font-size: 1.5rem;
        display: block;
        border-bottom: 1px solid #ececec;
        padding-top: 1.5rem;
        padding-bottom: 1.5rem;
      }
    }
    .btn-contact {
      margin-top: 4rem;
      text-align: center;
      a {
        background-color: var(--color-secondary);
        text-decoration: none;
        color: #fff;
        font-family: $bold-font;
        padding: 1rem 2rem;
        border-radius: 2rem;
        display: block;
        width: 60%;
        margin: 0 auto;
        margin-top: 1.5rem;
      }
    }
  }
}

.hide {
  max-height: 0;
  overflow: hidden;
  transition: max-height 0.5s ease-out;
}

.visible {
  max-height: 100vh;
}